import { ref, watch } from 'vue'

export const email = ref(window.localStorage.getItem('email') || undefined)
export const cameraId = ref(
  window.localStorage.getItem('cameraId') || undefined
)

watch(email, newEmail => {
  if (newEmail) {
    window.localStorage.setItem('email', newEmail)
  } else {
    window.localStorage.removeItem('email')
  }
})

watch(cameraId, newCameraId => {
  if (newCameraId) {
    window.localStorage.setItem('cameraId', newCameraId)
  } else {
    window.localStorage.removeItem('cameraId')
  }
})
