/**
 * Detect mobile device
 */
function detectMobile() {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  )
}

export const isMobile = detectMobile()

export function hexToRgb(
  hex: string,
  normalized = false
): [number, number, number] | null {
  const divider = normalized ? 255 : 1
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? [
        parseInt(result[1], 16) / divider,
        parseInt(result[2], 16) / divider,
        parseInt(result[3], 16) / divider
      ]
    : null
}

export type ColorRGB =
  | [number, number, number]
  | { r: number; g: number; b: number }
export type ColorRGBA =
  | [number, number, number, number]
  | { r: number; g: number; b: number; a: number }

export function normalizeRgba<T extends ColorRGB | ColorRGBA>(color: T): T {
  if (Array.isArray(color)) {
    // @ts-ignore
    return color.map(rgb => rgb / 255)
  } else {
    return Object.entries(color).reduce((acc, [key, value]) => {
      if (value === undefined) return acc
      // @ts-ignore
      acc[key as keyof RGBA] = value / 255
      return acc
    }, {} as typeof color)
  }
}
