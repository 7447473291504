<template>
  <div class="loader">
    <div class="spinner">
      <div class="spinner-item"></div>
      <div class="spinner-item"></div>
    </div>

    <p class="text">Just a second...</p>
  </div>
</template>

<style lang="scss" scoped>
.loader {
  @include center-content;
  flex-direction: column;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.spinner {
  --size: 75px;
  --animation-duration: 3000ms;
  position: relative;
  width: var(--size);
  height: var(--size);

  .spinner-item {
    position: absolute;
    top: calc(50% - var(--size) / 2);
    left: calc(50% - var(--size) / 2);
    width: var(--size);
    height: var(--size);
    background: var(--clr-spinner);
    border-radius: 50%;
    animation: spinner6 var(--animation-duration) ease-in-out infinite;

    @keyframes spinner6 {
      0%,
      100% {
        transform: scale(0.25);
        opacity: 1;
      }

      50% {
        transform: scale(1);
        opacity: 0;
      }
    }
  }

  .spinner-item:nth-of-type(1) {
    --clr-spinner: #{$black};
  }

  .spinner-item:nth-of-type(2) {
    --clr-spinner: #{rgba($black, 0.6)};
    animation-delay: calc(var(--animation-duration) / -2);
  }
}

.text {
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: -0.3px;
}

.hidden {
  opacity: 0;
}
</style>
