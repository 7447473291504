import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL
})

export default {
  uploadVideo: (videoFile: Blob, email?: string) => {
    const formData = new FormData()
    formData.append('video', videoFile, 'recorded-video.mp4')
    if (email) {
      formData.append('email', email)
    }

    return axios.post('/upload-video', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getVideoUrl: (videoId: string) => axios.get(`/get-video/${videoId}`),
  submitEmail: (email: string, videoId: string) =>
    axios.post('/enter-email', { email, videoId })
}
