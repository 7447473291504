<template>
  <div>
    <router-view class="router-view" />
  </div>
</template>

<style lang="scss">
@import '~ress';
@import './assets/scss/main.scss';

.router-view {
  position: relative;
  z-index: 1;
}
</style>
