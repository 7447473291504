import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Error from '../views/Error.vue'
import Upload from '../views/Upload.vue'
import Share from '../views/Share.vue'

export enum Routes {
  Home = 'Home',
  Error = 'Error',
  Upload = 'Upload',
  Share = 'Share'
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: Routes.Home,
    component: Home
  },
  {
    path: '/error',
    name: Routes.Error,
    component: Error
  },
  {
    path: '/upload',
    name: Routes.Upload,
    component: Upload
  },
  {
    path: '/share/:videoId?',
    name: Routes.Share,
    component: Share
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
